import React from "react"
import { withRouter } from "react-router-dom"
import queryString from "query-string"
import { useSelector, useDispatch } from "react-redux"
import UserFieldEditorFooter from "../UserFieldEditorFooter/UserFieldEditorFooter"
import { WithDrawerConsumer } from "../../Contexts/DrawerContext/withDrawerConsumer"
import constants from "../../Constants"
import { profileSteps } from "../../Redux/Reducers/Application/profileMetadata"
import { spouseProfileSteps } from "../../Redux/Reducers/SpouseApplication/spouseProfileMetadata"
import Actions from "../../Redux/Actions"
import { allFormsFilled } from "../../Redux/Utils/reducerUtils"

const ProfileFooter = (props) => {
  const dispatch = useDispatch()

  const reduxStateData = useSelector(({ user, application }) => ({
    currentInputData: application.currentInputData,
    profileMetadata: (user.user.is_spouse === 0 || user.user.is_spouse === 2 || user.user.is_spouse === 3) ? application.profileMetadata : spouseProfileSteps,
    user: user.user,
    willLocate: application.userWillReLocate
  }))

  const getNextPage = () => {
    const pageTitle = queryString.parse(props.history.location.search).title
    const currentPageIndex = (reduxStateData.user.is_spouse === 0 || reduxStateData.user.is_spouse === 2 || reduxStateData.user.user.is_spouse === 3) ? profileSteps.findIndex(
      (elem) => elem.stepId === pageTitle
    ) :
    spouseProfileSteps.findIndex(
      (elem) => elem.stepId === pageTitle
    )
/*
    if (pageTitle === "location") {
      if (reduxStateData.willLocate) {
        return profileSteps[currentPageIndex + 1].url
      }
      return profileSteps[currentPageIndex + 2].url
    }*/
    if (reduxStateData.user.is_spouse === 0 || reduxStateData.user.is_spouse === 2 || reduxStateData.user.user.is_spouse === 3) {
      return profileSteps[currentPageIndex + 1].url
    } else {
      return spouseProfileSteps[currentPageIndex + 1].url
    }
  }

  const getPreviousPage = () => {
    const pageTitle = queryString.parse(props.history.location.search).title

    const currentPageIndex = (reduxStateData.user.is_spouse === 0 || reduxStateData.user.is_spouse === 2 || reduxStateData.user.user.is_spouse === 3) ? profileSteps.findIndex(
      (elem) => elem.stepId === pageTitle
    ) : spouseProfileSteps.findIndex(
      (elem) => elem.stepId === pageTitle
    )

    if (pageTitle === "military") {
      if (reduxStateData.willLocate) {
        return profileSteps[currentPageIndex - 1].url
      }
      return profileSteps[currentPageIndex - 2].url
    }
    if (reduxStateData.user.is_spouse === 0 || reduxStateData.user.is_spouse === 2 || reduxStateData.user.user.is_spouse === 3) {
      return profileSteps[currentPageIndex - 1].url
    } else {
      return spouseProfileSteps[currentPageIndex - 1].url
    }
  }

  const nextHandler = async () => {
    const pageTitle = queryString.parse(props.history.location.search).title
    const last_page_check = reduxStateData.user.is_spouse === 1 
    ? constants.SPOUSE_PROFILE_LAST_PAGE 
    : constants.PROFILE_LAST_PAGE;
    if (pageTitle !== last_page_check) {
      if (reduxStateData.currentInputData) {
        await dispatch(
          Actions.saveApplication(
            reduxStateData.currentInputData,
            props.history
          )
        )
        dispatch(Actions.toggleUnfilled())
      }
      props.history.push(getNextPage())
    } else if (allFormsFilled(reduxStateData.profileMetadata)) {
      if (reduxStateData.currentInputData) {
        dispatch(
          Actions.saveApplication(
            reduxStateData.currentInputData,
            props.history,
            "/home"
          )
        )
      } else {
        props.history.push("/home")
      }
    } else {
      dispatch(
        Actions.saveApplication(reduxStateData.currentInputData, props.history)
      )
      dispatch(Actions.toggleUnfilled())
      props.openDrawerAndPreventTertiary()
    }
  }

  const previousHandler = () => {
    const pageTitle = queryString.parse(props.history.location.search).title
    if (pageTitle !== constants.PROFILE_FIRST_PAGE) {
      if (reduxStateData.currentInputData) {
        dispatch(
          Actions.saveApplication(
            reduxStateData.currentInputData,
            props.history
          )
        )
      }
      props.history.push(getPreviousPage())
    }
  }

  const last_page = (reduxStateData.user.is_spouse === 0 || reduxStateData.user.is_spouse === 2 || reduxStateData.user.user.is_spouse === 3) ? constants.PROFILE_LAST_PAGE : constants.SPOUSE_PROFILE_LAST_PAGE

  const saveAndExitHandler = (checkSubmittability, inputData) => () => {
    if (inputData) {
      props.saveApplication(inputData, props.history)
      dispatch(Actions.toggleHasCheckedProfile(true))

      if (props.user.has_submitted_application) {
        props.history.push("/home")
      } else {
        props.logout()
        props.history.push("/account/login")
      }
    } else if (props.user.has_submitted_application) {
      props.history.push("/home")
    } else {
      props.logout()
      props.history.push("/account/login")
    }
    props.closeAllDrawers()
  }

  const pageTitle = queryString.parse(props.history.location.search).title

  return (
    <UserFieldEditorFooter
      nextHandler={nextHandler}
      previousHandler={previousHandler}
      renderPreviousButton={pageTitle !== constants.PROFILE_FIRST_PAGE}
      saveAndExitHandler={saveAndExitHandler}
      toRenderSaveAndExitButton={!reduxStateData.user.has_submitted_application}
      nextText={
        pageTitle === last_page ? "Finish Editing" : "Next"
      }
    />
  )
}

export default withRouter(WithDrawerConsumer(ProfileFooter))
